import React from 'react';
import { string } from 'prop-types';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import { LOYALTY_SHARE_ROUTE } from 'constants/navigation';
import Skeleton from 'components/cards/skeleton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/pro-regular-svg-icons';
import { faShareAlt as solid } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './styles';

const BaseCard = loadable(() => import('components/cards/base-card'));

const ShareCard = ({ passId, title }) => {
  const styles = useStyles();

  return <BaseCard
          classes={styles}
          onCardClick={() => navigate(`${LOYALTY_SHARE_ROUTE}?passId=${passId}`)}
          title="Share"
          description="Send this loyalty card, print a QR code get the code to add it to your website"
          buttonMessage="Share loyalty card"
          imageChild={
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faShareAlt} size="8x" className={styles.largeIcon}/>
            </div>
            }
          iconChild={
            <span className={styles.icon}>
              <FontAwesomeIcon icon={solid} />
            </span>
          }
          fallback={<Skeleton/>}
          />;
};

ShareCard.propTypes = {
  passId: string.isRequired,
  title: string.isRequired,
};

export default ShareCard;
