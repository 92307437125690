import React from 'react';
import { string, bool } from 'prop-types';

import MessageCard from 'components/cards/message';
import AnalyticsCard from 'components/cards/analytics';
import RewardCard from 'components/cards/loyalty/reward';
import ShareCard from 'components/cards/share';
import UpdateCard from 'components/cards/update';
import SkeletonCard from 'components/cards/skeleton';

import { features } from 'gatsby-env-variables';
import useStyles from 'apputil/view-styles';

import { Grid } from '@material-ui/core';

const ActionActiveView = ({
  passId,
  title,
  canShare = true,
  canReward = true,
  loading = true,
}) => {
  const styles = useStyles();

  const { completeUpdateMessaging, completeAnalytics } = features;

  return (
        <div className={styles.menucontent}>
          <Grid spacing={2}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              className={styles.grid}
            >
            {canShare && (
              <Grid item md={4} sm={6} xs={12}>
                {loading ? <SkeletonCard/> : <ShareCard passId={passId} title={title}/>}
              </Grid>
            )}
            {canReward && (
              <Grid item md={4} sm={6} xs={12}>
                {loading ? <SkeletonCard/> : <RewardCard passId={passId} title={title}/>}
              </Grid>
            )}
            {canShare && (
              <Grid item md={4} sm={6} xs={12}>
                {loading ? <SkeletonCard/> : <UpdateCard passId={passId} title={title}/>}
              </Grid>
            )}
            {completeAnalytics && (
              <Grid item md={4} sm={6} xs={12}>
                {loading ? <SkeletonCard/> : <AnalyticsCard passId={passId} title={title}/>}
              </Grid>
            )}
            {completeUpdateMessaging && (
              <Grid item md={4} sm={6} xs={12}>
                {loading ? <SkeletonCard/> : <MessageCard passId={passId} title={title}/>}
              </Grid>
            )}
          </Grid>
        </div>
  );
};

ActionActiveView.propTypes = {
  passId: string.isRequired,
  title: string.isRequired,
  canShare: bool,
  canReward: bool,
  loading: bool,
};

export default ActionActiveView;
