import React from 'react';
import { string } from 'prop-types';
import { navigate } from 'gatsby';
import loadable from '@loadable/component';
import { ANALYTICS_ROUTE } from 'constants/navigation';
import Skeleton from 'components/cards/skeleton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnalytics } from '@fortawesome/pro-solid-svg-icons';

import useStyles from './styles';

const BaseCard = loadable(() => import('components/cards/base-card'));

const AnalyticsCard = ({ passId, title }) => {
  const styles = useStyles();

  return <BaseCard
          classes={styles}
          onCardClick={() => navigate(`${ANALYTICS_ROUTE}?passId=${passId}`)}
          title="Analytics"
          description="Some insights into how you loyalty card is working out"
          buttonMessage="Loyalty analytics"
          imageChild={
            <div className={styles.iconContainer}>
              <FontAwesomeIcon icon={faAnalytics} size="8x" className={styles.largeIcon}/>
            </div>
            }
              iconChild={
                <span className={styles.icon}>
                  <FontAwesomeIcon icon={faAnalytics} />
                </span>
              }
          fallback={<Skeleton/>}
          />;
};

AnalyticsCard.propTypes = {
  passId: string.isRequired,
  title: string.isRequired,
};

export default AnalyticsCard;
